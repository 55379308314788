import { inject, Injectable, signal } from '@angular/core';
import { HECoSSubjectsApiService } from '@uc/web/shared/data-access';

@Injectable({ providedIn: 'root' })
export class HECosSubjectValidatorService {
	private _hecosSubjectsApiSrv = inject(HECoSSubjectsApiService);
	error = signal<string | undefined>(undefined);

	private _hecosSubjects = signal<{ subjects: string[] } | undefined>(undefined);

	constructor() {
		this.fetchHECoSSubjects();
	}

	async isHECoSSubject(searchTerm: string) {
		let subjects = this._hecosSubjects()?.subjects || [];

		if (subjects.length === 0) {
			subjects = (await this.fetchHECoSSubjects()) || [];
		}

		subjects = subjects.map((subject) => subject.toLowerCase());

		const subject = searchTerm.replace(/ /g, '-').toLowerCase();

		return subjects?.includes(subject);
	}

	async fetchHECoSSubjects() {
		if (this.error()) return;

		if (this._hecosSubjects()) return this._hecosSubjects()?.subjects;

		const hecosSubjects = await this._hecosSubjectsApiSrv.getHECoSSubjects();
		if (!hecosSubjects) {
			this.error.set('Unable to fetch HECOS subjects');
			return [];
		}
		this._hecosSubjects.set(hecosSubjects);
		this.error.set(undefined);
		return hecosSubjects.subjects;
	}
}
