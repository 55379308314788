import { NavCard } from '../models/navigation.models';

export const events = {
	sublinks: [
		{
			heading: '',
			param: 'type',
			links: [
				{
					title: 'University Open Days',
					value: 'open',
					description: 'Discover and book uni open days.',
				},
				{
					title: 'University Virtual Events',
					value: 'virtual_webinar',
					description: 'Explore virtual university events.',
				},
			],
		},
		{
			heading: '',
			param: 'studyLevel',
			links: [
				{
					title: 'Undergraduate Events',
					value: 'undergraduate',
					description: 'Search undergraduate open days.',
				},
				{
					title: 'Postgraduate Events',
					value: 'postgraduate',
					description: 'Search postgraduate events.',
				},
			],
		},
	],
	// backup in case there are no content cards coming from the CMS
	furtherInformation: [
		{
			image: 'https://cdn.universitycompare.com/content/static-images/app-content-cart-2.jpg',
			title: "Request Info From Uni's",
			description:
				'Get the help you need direct from the university. Ask about accommodation, your course and university societies.',
			href: '/request/select',
			linkText: 'Find out more',
			external: false,
		},
		{
			image: 'https://cdn.universitycompare.com/img/app-img2.jpg',
			title: 'Bulk Order Prospectuses',
			description:
				'Bulk order prospectus from universities and have them delivered to your door for free.',
			href: '/select',
			linkText: 'Find out more',
			external: false,
		},
	] as NavCard[],
	browse: '/open-days/search',
};

export const landingCard = {
	title: 'Event Search and Discover',
	value: '/open-days/',
	description:
		'Take your search to the next level by searching for university events. Discover recommended open days and other events.',
	new: true,
};
